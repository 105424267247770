import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Body } from "@ninjaone/components"

export const StyledTooltipContent = styled.div`
  position: relative;
  padding: ${tokens.spacing[1]};
  background-color: ${({ theme }) => theme.colorBackgroundWidget};
  border: 1px solid ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  min-width: 110px;
  box-shadow: ${({ theme }) => theme.elevationStrong};

  span.divider {
    color: ${({ theme }) => theme.colorBorderWeak};
  }

  output {
    padding: 0; // overwrite bootstrap
  }
`

export const StyledTooltipContentHeader = styled.div`
  padding-bottom: ${tokens.spacing[1]};
  border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  margin-bottom: ${tokens.spacing[1]};
  padding-left: ${tokens.spacing[2]};
`

const SimpleChartTooltip = ({ title, value, valueSecondary, valueTertiary }) => {
  return (
    <StyledTooltipContent>
      <output aria-live="assertive">
        <StyledTooltipContentHeader>
          <Body type="bodyXs">{title}</Body>
        </StyledTooltipContentHeader>
        <Box paddingLeft={tokens.spacing[2]} paddingRight={tokens.spacing[2]}>
          {!!value && (
            <Body color="colorTextStrong" fontWeight={tokens.typography.fontWeight.medium} type="headingS">
              {value}
            </Body>
          )}
          <Flex gap={tokens.spacing[1]} alignItems="center">
            {!!valueSecondary && (
              <Body color="colorTextWeakest" type="bodyXs">
                {valueSecondary}
              </Body>
            )}
            {!!valueTertiary && (
              <>
                <span className="divider">|</span>
                <Body color="colorTextWeakest" type="bodyXs">
                  {valueTertiary}
                </Body>
              </>
            )}
          </Flex>
        </Box>
      </output>
    </StyledTooltipContent>
  )
}

export default SimpleChartTooltip

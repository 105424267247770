import { forwardRef } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { Trigger } from "@radix-ui/react-popover"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization/autocomplete"
import { isRequiredIf } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"

import { ClearIconTooltip, ErrorIconTooltip } from "../IconTooltips"
import VisibilityToggle from "../VisibilityToggle"

const StyledActions = styled.div`
  position: absolute;
  top: 9px;
  ${({ isDateTime }) => (isDateTime ? `left: ${tokens.spacing[2]}` : `right: ${tokens.spacing[3]}`)};
  display: flex;
  align-items: ${({ toggleSecureInputVisibility }) => (toggleSecureInputVisibility ? "baseline" : "center")};
  gap: ${tokens.spacing[1]};
`

const StyledButtonBase = styled.button`
  width: 20px;
  height: 20px;
  padding: 0;
  border: 0;
  background: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledIconButton = styled(StyledButtonBase)`
  color: ${({ theme }) => theme.colorTextWeakest};

  & svg {
    font-size: ${tokens.typography.fontSize.body};
  }
`

const StyledClearBtn = styled(StyledButtonBase)`
  color: ${({ theme }) => theme.colorTextStrong};
`

const StyledErrorContainer = styled.div`
  & > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 21px;
    border: 0;
    border-radius: ${tokens.borderRadius[1]};
    background: none;

    &:focus-visible {
      outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
    }
  }
`

const IconButton = forwardRef(({ ariaLabel, dataset, icon, ...rest }, forwardedRef) => {
  return (
    <StyledIconButton ref={forwardedRef} aria-label={ariaLabel} {...dataset} {...rest}>
      {icon}
    </StyledIconButton>
  )
})

const InputActions = ({
  ariaErrorId,
  dataAttribute,
  disableClear,
  disabled,
  error,
  iconButton,
  iconIsTrigger,
  isDateTime,
  isSecureInputVisible,
  onClearClick,
  RightSideIcon,
  secureInputNotVisibleLabel,
  secureInputVisibleLabel,
  toggleSecureInputVisibility,
  tooltipAlignment,
  tooltipPosition,
}) => {
  const dataset = { [`data-ninja-${dataAttribute}`]: "true" }

  return (
    <StyledActions {...{ isDateTime, toggleSecureInputVisibility }}>
      {!disableClear && !disabled && (
        <StyledClearBtn
          aria-label={localized("Clear input")}
          data-ninja-input-clear=""
          id="clearBtn"
          onClick={onClearClick}
          type="button"
        >
          <ClearIconTooltip
            label={localized("Clear")}
            {...{
              tooltipAlignment,
              tooltipPosition,
            }}
          />
        </StyledClearBtn>
      )}

      {error && !disabled && (
        <StyledErrorContainer>
          <ErrorIconTooltip
            ariaError={`${localized("Error")} ${error}`}
            ariaId={ariaErrorId}
            ariaLive="assertive"
            triggerAsChild={false}
            {...{
              error,
              tooltipAlignment,
              tooltipPosition,
            }}
          />
        </StyledErrorContainer>
      )}

      {iconButton && iconIsTrigger && (
        <Trigger asChild>
          <IconButton
            {...{
              ariaLabel: iconButton.ariaLabel,
              icon: iconButton.icon,
              onBlur: iconButton.onBlur,
              onClick: iconButton.onClick,
              onKeyDown: iconButton.onKeyDown,
              type: "button",
              disabled,
              dataset,
            }}
          />
        </Trigger>
      )}

      {iconButton && !iconIsTrigger && (
        <StyledIconButton
          aria-label={iconButton.ariaLabel}
          onBlur={iconButton.onBlur}
          onClick={iconButton.onClick}
          type="button"
          {...{ disabled, dataset }}
        >
          {iconButton.icon}
        </StyledIconButton>
      )}

      {toggleSecureInputVisibility && (
        <VisibilityToggle
          isNotVisibleLabel={secureInputNotVisibleLabel}
          isVisible={isSecureInputVisible}
          isVisibleLabel={secureInputVisibleLabel}
          onClick={toggleSecureInputVisibility}
          positioningStyles="margin-top: -6px;"
          {...{ disabled }}
        />
      )}

      {RightSideIcon}
    </StyledActions>
  )
}

InputActions.propTypes = {
  /**
   * The id of the input to associate the error with.
   */
  ariaErrorId: isRequiredIf(
    PropTypes.string,
    props => props.hasOwnProperty("error"),
    "`ariaErrorId` is required when `error` is defined",
  ),
  /**
   * A dynamic data attribute for full story targeting. Automatically prefixed
   * with `data-ninja`.
   */
  dataAttribute: isRequiredIf(
    PropTypes.string,
    props => props.hasOwnProperty("iconButton"),
    "`dataAttribute` is required when using `iconButton`",
  ),
  /**
   * Determines if the clear button should be rendered.
   */
  disableClear: PropTypes.bool,
  /**
   * The disabled state of the input, determines if the icon button should be disabled.
   */
  disabled: PropTypes.bool,
  /**
   * The error from the input component.
   */
  error: PropTypes.string,
  /**
   * The icon button and it's props
   */
  iconButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    onBlur: isRequiredIf(
      PropTypes.func,
      props => props.required === true,
      "`onBlur` is required when the `required` prop is `true`",
    ),
    icon: PropTypes.element.isRequired,
    ariaLabel: PropTypes.string.isRequired,
  }),
  /**
   * Determines if the icon button is a radix trigger.
   */
  iconIsTrigger: PropTypes.bool,
  /**
   * Internal prop used by the `<DateTimePicker>` for styling.
   */
  isDateTime: PropTypes.bool,
  /**
   * The visibility state of a secure input.
   */
  isSecureInputVisible: PropTypes.bool,
  /**
   * Event handler run when the clear button is clicked.
   */
  onClearClick: isRequiredIf(
    PropTypes.func,
    props => !props.hasOwnProperty("disableClear"),
    "`onClearClick` is required",
  ),
  /**
   * A static icon that appears on the right side of the component.
   */
  RightSideIcon: PropTypes.oneOfType([PropTypes.elementType, PropTypes.object]),
  /**
   * The label text for a secure input when the value is obscured.
   */
  secureInputNotVisibleLabel: PropTypes.string,
  /**
   * The label text for a secure input when the value is not obscured.
   */
  secureInputVisibleLabel: PropTypes.string,
  /**
   * The callback run when the visibility of a secure input changes.
   */
  toggleSecureInputVisibility: PropTypes.func,
  /**
   * The horizontal alignment of the tooltip.
   */
  tooltipAlignment: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * The position of the tooltip in relation to the trigger.
   */
  tooltipPosition: PropTypes.oneOf(["top", "right", "bottom", "left"]),
}

export default InputActions

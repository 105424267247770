import { forwardRef } from "react"
import PropTypes from "prop-types"
import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { Root as VisuallyHidden } from "@radix-ui/react-visually-hidden"
import { sizer } from "@ninjaone/utils"
import { StyledFontAwesomeIcon } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Tooltip from "./Tooltip"

const VARIANTS = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
  TERTIARY: "tertiary",
  BLUE: "blue",
}

const getStylesByVariant = ({ theme, variant, active, disabled }) => {
  switch (variant) {
    case VARIANTS.PRIMARY:
      return `
        color: ${!disabled ? theme.colorTextWeak : theme.colorTextDisabled};
        background-color: ${active ? theme.colorForegroundHover : "transparent"};

        ${!disabled &&
          `
          &:hover {
            background-color: ${theme.colorForegroundHover};
          }
        `}
      `
    case VARIANTS.SECONDARY: {
      return `
        color: ${theme.colorTextAction};
        background-color: ${active ? theme.colorForegroundHover : "transparent"};

        &:hover {
          background-color: ${theme.colorForegroundHover};
        }
      `
    }
    case VARIANTS.TERTIARY: {
      return `
        color: ${theme.color.yellow};
        background-color: ${active ? theme.colorForegroundHover : "transparent"};

        &:hover {
          background-color: ${theme.colorForegroundHover};
        }
      `
    }

    case VARIANTS.BLUE: {
      const primaryColor = disabled ? theme.colorBackgroundCtaDisabled : theme.colorBackgroundCta

      return `
        color: ${disabled ? theme.colorTextDisabled : theme.colorTextHighContrast};
        background-color: ${primaryColor};

        ${!disabled &&
          `
          &:hover {
            background-color: ${theme.colorForegroundCtaHover};
          }
        `}
      `
    }

    default:
      return ""
  }
}

const getDimensionsBySize = size => {
  switch (size) {
    case "sm":
      return `
        font-size: 16px;
        width: 32px;
        height: 32px;
      `
    case "md":
      return `
        font-size: 16px;
        width: 38px;
        height: 38px;
      `
    case "lg":
      return `
        font-size: 18px;
        width: 38px;
        height: 38px;
      `
    default:
      return ""
  }
}

const StyledButton = styled.button`
  background-color: transparent;
  outline: none;
  border: ${({ border }) => border || "none"};
  border-radius: ${sizer(1)};
  display: flex;
  justify-content: center;
  align-items: center;
  rotate: ${({ rotate }) => rotate || ""};

  ${({ theme, variant, active, disabled }) => getStylesByVariant({ theme, variant, active, disabled })}
  ${({ disabled }) => (!disabled ? "cursor: pointer;" : "")}
  ${({ size }) => getDimensionsBySize(size)}

  &:focus-visible {
    outline: 2px auto ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledButtonDiv = StyledButton.withComponent("div")

const IconButton = forwardRef(
  (
    {
      icon,
      Indicator,
      handleClick = () => {},
      tooltip,
      showTooltipWhenDisabled = false,
      children,
      size = "md",
      active,
      variant = "primary",
      disabled,
      isHoverDropdownTrigger,
      hideTooltip,
      renderAsDiv,
      tooltipZIndex,
      tooltipPortal,
      tooltipAlignRight,
      ...rest
    },
    forwardedRef,
  ) => {
    const theme = useTheme()
    const StyledButtonElement = renderAsDiv ? StyledButtonDiv : StyledButton

    const _IconButton = (
      <StyledButtonElement
        {...{
          theme,
          size,
          variant,
          active,
          disabled,
          ref: forwardedRef,
          onClick: handleClick,
          ...(!renderAsDiv && { type: "button" }),
          ...rest,
        }}
      >
        {tooltip && <VisuallyHidden>{tooltip}</VisuallyHidden>}
        {children ?? <StyledFontAwesomeIcon icon={icon} fixedWidth />}
        {Indicator && <Indicator />}
      </StyledButtonElement>
    )

    if (tooltip && (showTooltipWhenDisabled || !disabled)) {
      return (
        <Tooltip
          label={tooltip}
          contentZIndex={tooltipZIndex}
          portal={tooltipPortal}
          hideTooltip={hideTooltip}
          {...{
            ...(isHoverDropdownTrigger && {
              hideOnTriggerClick: false,
            }),
            ...(tooltipAlignRight && { align: "end" }),
          }}
        >
          {_IconButton}
        </Tooltip>
      )
    }

    return _IconButton
  },
)

export default IconButton

IconButton.propTypes = {
  icon: PropTypes.object,
  children: PropTypes.element,
  Indicator: PropTypes.object,
  handleClick: PropTypes.func,
  tooltip: PropTypes.string,
  showTooltipWhenDisabled: PropTypes.bool,
  active: PropTypes.bool,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "blue"]),
  isHoverDropdownTrigger: PropTypes.bool,
  hideTooltip: PropTypes.bool,
  tooltipZIndex: PropTypes.number,
  tooltipPortal: PropTypes.bool,
}

export const SINGLE_SELECT_DISPLAY_TYPE = {
  ICON: "icon",
  ICON_WITH_TEXT: "icon_with_text",
}

export const maxOptionsToHideCombobox = 5

export const defaultSelectHeight = "38px"

export const selectTriggerMinHeight = "38px"

export const arrowIconWidth = "52px"
export const secondActionIconWidth = "41px"
export const optionIconWidth = "28px"
export const selectedOptionIconWidth = "24px"

export const comboboxHeight = "46px"

export const defaultOptionHeight = "38px"

export const popoverMaxHeight = "min(var(--popover-available-height, 400px), 400px)"
export const popoverMinWidth = "140px"
export const popoverMaxWidth = "680px"

export const emptyStatePopoverWidth = "298px"
export const emptyStatePopoverHeight = "194px"

import PropTypes from "prop-types"
import tokens from "@ninjaone/tokens"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import Text from "../Text"

const Body = ({
  ariaHidden,
  as,
  children,
  code,
  color,
  fontWeight,
  icon,
  iconGap = tokens.spacing[3],
  id,
  italic,
  hideTooltip,
  marginBottom,
  marginTop,
  textWrap,
  textWrapLineLimit,
  tooltipPosition,
  tooltipText,
  type,
  uppercase,
  visuallyHidden,
  wordWrap,
}) => {
  const text = (
    <Text
      {...{
        ariaHidden,
        as,
        code,
        color,
        fontWeight,
        id,
        italic,
        hideTooltip,
        marginBottom: marginBottom ?? 0,
        marginTop: marginTop ?? 0,
        textWrap,
        textWrapLineLimit,
        tooltipPosition,
        tooltipText,
        type,
        uppercase,
        visuallyHidden,
        wordWrap,
      }}
    >
      {children}
    </Text>
  )

  if (icon) {
    return (
      <Flex alignItems="center" gap={iconGap}>
        {icon}
        {text}
      </Flex>
    )
  }

  return text
}

Body.defaultProps = {
  as: "p",
  color: "colorTextStrong",
  fontWeight: tokens.typography.fontWeight.regular,
  type: "body",
}

Body.propTypes = {
  /**
   * The semantic HTML element the component will render as.
   */
  as: PropTypes.oneOf(["dt", "dd", "p", "span", "strong", "legend", "code", "li"]),
  /**
   * Text content for the component.
   */
  children: PropTypes.node,
  /**
   * Sets the font family for code blocks.
   */
  code: PropTypes.bool,
  /**
   * The color of the component.
   */
  color: PropTypes.string,
  /**
   * Sets the font weight for the component.
   */
  fontWeight: PropTypes.oneOf([400, 500, 600]),
  /**
   * Determines if tooltip should be hidden.
   */
  hideTooltip: PropTypes.bool,
  /**
   * The icon to render before the text.
   */
  icon: PropTypes.element,
  /**
   * The gap between the icon and the text.
   */
  iconGap: PropTypes.string,
  /**
   * The id of the component.
   */
  id: PropTypes.string,
  /**
   * Sets the font style to italic.
   */
  italic: PropTypes.bool,
  /**
   * Determines if text should wrap.
   */
  textWrap: PropTypes.bool,
  /**
   * The number of lines text should wrap
   */
  textWrapLineLimit: PropTypes.number,
  /**
   * Position of the tooltip.
   */
  tooltipPosition: PropTypes.func,
  /**
   * Overrides the tooltip text with the specified string.
   */
  tooltipText: PropTypes.string,
  /**
   * Determines the font size and light height for the component.
   */
  type: PropTypes.oneOf(["body", "bodyXs", "headingS", "headingXs"]),
  /**
   * Transforms the text to uppercase.
   */
  uppercase: PropTypes.bool,
  /**
   * Visually hide the component.
   */
  visuallyHidden: PropTypes.bool,
  /**
   * CSS word-wrap property that allows long words to be able to break and wrap onto the next line
   */
  wordWrap: PropTypes.string,
}

export default Body

import $ from "jquery"
import React, { cloneElement, isValidElement } from "react"
import { unmountComponentAtNode } from "react-dom"
import { Provider } from "react-redux"
import { createClient, Provider as GraphQLProvider } from "urql"
import { HashRouter as ReactRouter } from "react-router-dom"
import { nanoid } from "nanoid"
import { isEscapeKey, renderReactComponentIfElementExists } from "js/includes/common/utils"
import { QueryProvider } from "js/state/queryProvider"

export default async function showModal(Component, options = {}) {
  const {
    containerId = "react-modal",
    withProvider = false,
    withQueryProvider = false,
    withGraphQLProvider = false,
    withReactRouter = false,
    graphQLUrl,
    isFullScreen = false,
  } = options

  if (!isValidElement(Component)) throw new Error("Invalid element passed to showModal function")

  const portal = document.getElementById(containerId)
  const lastActiveElement = document.activeElement

  const container = document.createElement("div")
  container.setAttribute("id", nanoid())
  container.hidden = false

  if (isFullScreen) {
    container.setAttribute(
      "style",
      `
      width: 100vw;
      height: 100vh;
      background-color: #fff;
      margin: 0 !important;
      position: absolute;
      z-index: 2050;
      left: 0;
      top: 0;
    `,
    )
  }

  portal.appendChild(container)
  portal.focus()

  const findLastFocusableElement = element => {
    if (document.body.contains(element)) return element

    if (element.attributes["aria-labelledby"]) {
      const attributeElement = document.getElementById(element.attributes["aria-labelledby"].value)
      if (document.body.contains(attributeElement)) return attributeElement
    }

    if (element.parentNode) return findLastFocusableElement(element.parentNode)

    return null
  }

  const unmount = () => {
    unmountComponentAtNode(container)
    if (portal.contains(container) && container?.parentNode === portal) {
      portal.removeChild(container)
    }
    window.removeEventListener("popstate", unmount)
    window.removeEventListener("keydown", onKeyPress, false)

    const lastFocusableElement = findLastFocusableElement(lastActiveElement)
    if (lastFocusableElement) {
      lastFocusableElement.focus()
    }
  }

  const onKeyPress = e => {
    if (isEscapeKey(e) && portal.lastChild.id === container.id) {
      unmount()
    }
  }

  window.addEventListener("popstate", unmount)
  if (!isFullScreen) {
    window.addEventListener("keydown", onKeyPress)
  }

  Component = cloneElement(Component, { unmount })

  let WrappedComponent = Component

  if (withProvider) {
    WrappedComponent = <Provider store={window.store}> {WrappedComponent} </Provider>
  }

  if (withQueryProvider) {
    WrappedComponent = <QueryProvider> {WrappedComponent} </QueryProvider>
  }

  if (withGraphQLProvider) {
    const client = createClient({ url: graphQLUrl })
    WrappedComponent = <GraphQLProvider value={client}> {WrappedComponent} </GraphQLProvider>
  }

  if (withReactRouter) {
    WrappedComponent = <ReactRouter> {WrappedComponent} </ReactRouter>
  }

  renderReactComponentIfElementExists(WrappedComponent, container)

  $(document).off("focusin.modal")
}

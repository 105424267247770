/* eslint-disable jsx-a11y/prefer-tag-over-role */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { PureComponent } from "react"
import styled from "@emotion/styled"
import fastDeepEqual from "fast-deep-equal"
import _ from "underscore"
import qs from "qs"
import PropTypes from "prop-types"
import {
  always,
  clone,
  compose,
  cond,
  dropLast,
  eqProps,
  equals,
  evolve,
  filter,
  find,
  includes,
  is,
  isEmpty,
  map,
  prop,
  propEq,
  reject,
  T,
} from "ramda"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faTimes } from "@fortawesome/pro-solid-svg-icons"
import { Highlighter, Tooltip } from "@ninjaone/components"
import { InputErrorIcon, SelectIcon } from "@ninjaone/icons"
import tokens from "@ninjaone/tokens"
import { fetchJson, isNotNil, reportErrorAndShowMessage } from "js/includes/common/utils"
import { localizationKey, localized } from "js/includes/common/utils/ssrAndWebUtils"
import OutsideClickAlerter from "js/includes/components/OutsideClickAlerter"
import SingleSelectInput from "./SingleSelectInput"
import MultiSelectInput from "./MultiSelectInput"
import { sortListByName } from "js/state/helpers/listSort"
import { StyledErrorMessage } from "../Styled/Form"
import { Box, Flex } from "../Styled"
import Dropdown from "./Dropdown"

const getBorderColor = (validationState, theme) => {
  const validationColor = cond([
    [equals("error"), always(theme.colorAlertError)],
    [equals("success"), always(theme.colorAlertSuccess)],
    [equals("warning"), always(theme.colorAlertWarning)],
  ])

  return cond([
    [
      () => ["error", "success", "warning"].includes(validationState),
      ({ validationState }) => validationColor(validationState),
    ],
    [T, always(theme.colorBorderWeak)],
  ])
}

const hasSome = (list, key, inputValue) => list.some(item => item[key] === inputValue)

const StyledDropDownContainer = styled.div`
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.colorBackgroundInputDisabled : theme.colorBackgroundWidget};
  color: ${({ disabled, theme }) => (disabled ? theme.colorTextDisabled : theme.colorTextStrong)};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  position: ${({ isOutsideScreen }) => (isOutsideScreen ? "fixed" : "relative")};
  right: ${({ isOutsideScreen }) => (isOutsideScreen ? "5px" : "0px")};
  min-height: ${({ minHeight }) => minHeight};
  border-radius: ${({ borderRadius }) => borderRadius};
  outline: 0px !important;
  display: flex;
  ${({ useFilterStyling }) =>
    useFilterStyling &&
    `flex-direction: column;
    font-size: ${tokens.typography.fontSize.body};
  padding: ${tokens.spacing[1]}`};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  align-items: center;
  ${({ useSelectStyling, theme }) =>
    useSelectStyling &&
    `color: ${theme.colorTextStrong};
    font-size: ${tokens.typography.fontSize.body};`}

  &[data-ninja-searchable-dropdown] {
    border: 1px solid ${({ validationState, theme }) => getBorderColor(validationState, theme)};
    color: ${({ theme }) => theme.colorTextStrong};

    &[data-active="true"] {
      ${({ useFilterStyling, theme }) =>
        useFilterStyling
          ? `box-shadow: ${theme.elevationWeak};`
          : `outline: 2px solid ${theme.colorForegroundFocus} !important; // must override default style
            box-shadow: none;`}
    }

    [role="search"] {
      min-height: 36px;
    }
`

const StyledDropDownToggle = styled.div`
  align-items: center;
  justify-content: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
`

const StyledToggleContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${({ open, theme }) => (open ? theme.colorTextWeakest : theme.colorTextWeak)};
  ${({ useSelectStyling }) => useSelectStyling && `padding-left: 0px;`};

  &:hover {
    cursor: pointer;
  }
`

const StyledClearSelection = styled.div`
  height: 100%;
  cursor: pointer;
  margin: ${({ useSelectStyling }) => (useSelectStyling ? "auto 0 auto 12px" : "auto 12px")};
`

const StyledErrorIconContainer = styled.div`
  color: ${({ theme }) => theme.colorTextDanger};
  padding-right: ${({ useSelectStyling }) => (useSelectStyling ? tokens.spacing[1] : tokens.spacing[3])};
  padding-left: ${tokens.spacing[1]};
  margin-top: ${tokens.spacing[1]};
  display: flex;
  align-items: center;
`

const StyledInputContainer = styled(Flex)`
  width: 100%;
  min-height: ${({ minHeight }) => minHeight};
  ${({ useFilterStyling, theme }) =>
    useFilterStyling &&
    `border: 1px solid ${theme.colorBorderWeak};
     border-radius: ${tokens.spacing[1]};
     margin-bottom: ${tokens.spacing[1]};
     &:focus-within {
      box-shadow: 0 0 0 2px ${theme.colorForegroundFocus}
     }`};
`

const StyledSeparator = styled.span`
  align-self: stretch;
  background-color: ${({ theme }) => theme.colorBorderWeak};
  margin: ${tokens.spacing[2]} 0px;
  width: 1px;
`

const convertToStringIfNumber = val => (is(Number, val) ? val.toString() : val)

class SearchableDropDown extends PureComponent {
  state = {
    open: false,
    focus: false,
    fetching: false,
    isOutsideScreen: false,
    shouldContinueLoading: true,
    maxWidth: "100%",
    itemsLoaded: [],
    selected: this.props.value,
    inputValue: this.props.getInitialValue?.() ?? "",
    createdOptions: [],
    shouldDisableSorting: false,
    query: null,
    anchorNaturalId: null,
    focusedIndexRowDropdown: null,
  }

  containerRef = null
  dropdownRef = null

  setFetching = fetching => this.setState({ fetching })
  setItemsLoaded = itemsLoaded => this.setState({ itemsLoaded })
  setShouldContinueLoading = shouldContinueLoading => this.setState({ shouldContinueLoading })
  setAnchorNaturalId = anchorNaturalId => this.setState({ anchorNaturalId })
  setOpen = open => this.setState({ open })
  setFocus = focus => this.setState({ focus })
  setInputValue = inputValue => this.setState({ inputValue })
  setSelected = selected => this.setState({ selected })
  setIsOutsideScreen = isOutsideScreen => this.setState({ isOutsideScreen })
  setMaxWidth = maxWidth => this.setState({ maxWidth })
  setCreatedOptions = createdOptions => this.setState({ createdOptions })
  setShouldDisableSorting = shouldDisableSorting => this.setState({ shouldDisableSorting })
  setQuery = query => this.setState({ query })
  setFocusedIndexRowDropdown = focusedIndexRowDropdown => this.setState({ focusedIndexRowDropdown })

  keepOptionsInView = () => {
    const {
      props: { keepInView },
      setMaxWidth,
      setIsOutsideScreen,
    } = this
    const wrapperElement = this.containerRef

    const updatePosition = () => {
      const parentNode = wrapperElement.parentNode
      const parentNodeBoundaries = parentNode.getBoundingClientRect()
      const wrapperBoundaries = wrapperElement.getBoundingClientRect()

      if (this._isMounted && keepInView) {
        if (wrapperElement) {
          setMaxWidth(`${parentNodeBoundaries.width}px`)
          const hasSpace =
            (window.innerWidth || document.documentElement.clientWidth) - parentNodeBoundaries.left >
            wrapperBoundaries.width

          setIsOutsideScreen(!hasSpace)

          window.requestAnimationFrame(updatePosition)
        }
      }
    }

    keepInView && window.requestAnimationFrame(updatePosition)
  }

  getItemsFromServerToLoad = async () => {
    const {
      props: { pageSize, dataFormatter, endpoint, searchParams, getAnchorNaturalId, lastItemKey, responseParser },
      state: { query, anchorNaturalId },
      setShouldContinueLoading,
      setAnchorNaturalId,
    } = this

    try {
      const _qs = qs.stringify(
        {
          pageSize,
          ...(anchorNaturalId && getAnchorNaturalId({ anchorNaturalId })),
          ...searchParams({ query }),
        },
        { indices: false },
      )

      const payload = await fetchJson(`${endpoint}?${_qs}`)
      const searchResponse = responseParser(payload).map(dataFormatter)

      if (this._isMounted) {
        setAnchorNaturalId(searchResponse[searchResponse.length - 1]?.[lastItemKey])
        const shouldLoadMore = searchResponse.length === pageSize
        setShouldContinueLoading(shouldLoadMore)
      }

      return searchResponse
    } catch (error) {
      reportErrorAndShowMessage(error, localizationKey("Error while fetching data"))
      return []
    }
  }

  getFilteredItems = items => {
    const {
      props: { dataFilter, excludeSelectedFromSelectableValues },
      state: { selected },
    } = this
    return excludeSelectedFromSelectableValues && (selected?.length || selected?.id)
      ? filter(
          item =>
            !includes(item.id, Array.isArray(selected) ? map(prop("id"), selected) : [selected.id]) && dataFilter(item),
          items,
        )
      : items.filter(dataFilter)
  }

  search = async () => {
    const { getFilteredItems, getItemsFromServerToLoad, search } = this
    const itemsFromServer = await getItemsFromServerToLoad()
    let renderableItems = getFilteredItems(itemsFromServer)
    if (itemsFromServer.length && !renderableItems.length) {
      const items = await search()
      renderableItems = [...items]
    }
    return renderableItems
  }

  fetchData = async () => {
    const {
      props: { searchableKey, valueSelectorKey, onSelect, getDefaultSelected, additionalDefaultOptions },
      setItemsLoaded,
      setFetching,
      setInputValue,
      search,
      setSelected,
    } = this

    setFetching(true)

    const itemsResponse = await search()

    if (this._isMounted) {
      setItemsLoaded([...additionalDefaultOptions, ...itemsResponse])
      setFetching(false)

      if (getDefaultSelected && itemsResponse.length) {
        const defaultToSelect = getDefaultSelected(itemsResponse) ?? []
        setSelected(defaultToSelect)
        onSelect(defaultToSelect)
        setInputValue(
          is(Function, searchableKey)
            ? searchableKey(defaultToSelect)
            : defaultToSelect[searchableKey || valueSelectorKey],
        )
      }
    }
  }

  debouncedOnSearchChange = _.debounce(async query => {
    const {
      state: { open },
      props: { additionalDefaultOptions, isMulti },
      setOpen,
      setItemsLoaded,
      setShouldDisableSorting,
      search,
      setQuery,
      setShouldContinueLoading,
      getUnselectedOptions,
      getFilteredOptions,
      setAnchorNaturalId,
    } = this

    if (this._isMounted) {
      if (!query) {
        setShouldDisableSorting(false)
      }
      setQuery(query)
      setShouldContinueLoading(true)
      setAnchorNaturalId(null)
    }

    const searchedItems = await search()

    const unselected = isMulti ? getUnselectedOptions(additionalDefaultOptions) : additionalDefaultOptions
    const filteredOptions = query ? getFilteredOptions(unselected, query) : unselected

    if (this._isMounted) {
      setItemsLoaded([...filteredOptions, ...searchedItems])
      setShouldDisableSorting(true)
      !open && setOpen(true)
    }
  }, 300)

  getFilteredOptions = (list, query) => {
    const {
      props: { searchableKey },
      getFilteredOptions,
    } = this

    return filter(item => {
      if (item.options) {
        item.options = getFilteredOptions(item.options, query)
        return item.options.length
      }

      const selector = searchableKey ?? "label"
      const label = convertToStringIfNumber(is(Function, selector) ? selector(item) : item[selector])
      return label?.toLowerCase().includes(query.toLowerCase())
    }, list)
  }

  localSearch = query => {
    const {
      props: { isMulti, options },
      state: { createdOptions },
      getUnselectedOptions,
      setItemsLoaded,
      getFilteredOptions,
    } = this

    if (!query) {
      this._isMounted && setItemsLoaded(options)
      return
    }

    const clonedOptions = clone([...options, ...createdOptions])

    const unselected = isMulti ? getUnselectedOptions(clonedOptions) : clonedOptions
    setItemsLoaded(getFilteredOptions(unselected, query))
  }

  getUnselectedOptions = options => {
    const {
      state: { selected },
      props: { valueSelectorKey },
      getUnselectedOptions,
    } = this
    return compose(
      reject(isEmpty),
      map(item => {
        if (item?.options) {
          const unselectedOptionsItem = evolve({ options: getUnselectedOptions }, item)
          return isEmpty(unselectedOptionsItem.options) ? {} : unselectedOptionsItem
        }
        return selected.some(eqProps(valueSelectorKey, item)) ? {} : item
      }),
    )(options)
  }

  getItemsToRender = () => {
    const {
      state: { itemsLoaded, selected },
      props: { isMulti, options, excludeSelectedFromSelectableValues },
      getUnselectedOptions,
    } = this

    let items = []

    if (options) {
      items = isMulti ? getUnselectedOptions(itemsLoaded) : itemsLoaded
    } else if (itemsLoaded?.length) {
      items = itemsLoaded
    }

    return excludeSelectedFromSelectableValues && (selected?.length || selected?.id)
      ? filter(item => !includes(item.id, Array.isArray(selected) ? map(prop("id"), selected) : [selected.id]), items)
      : items
  }

  handleRowClick = async rowData => {
    const {
      state: { selected, inputValue },
      props: { isMulti, options, valueSelectorKey, shouldSelectOption, onSelect },
      setOpen,
      setFocus,
      setSelected,
      setInputValue,
      setItemsLoaded,
      setQuery,
      fetchData,
      setAnchorNaturalId,
    } = this

    const shouldSelect = await shouldSelectOption(rowData)
    if (!shouldSelect) return

    if (isMulti) {
      const findItem = item => item[valueSelectorKey] === rowData[valueSelectorKey]
      const wasPreviouslySelected = selected.some(findItem)
      if (!wasPreviouslySelected) {
        const selection = [...selected, rowData]
        setSelected(selection)
        setFocus(true)
        if (options) {
          setItemsLoaded(options)
        } else {
          setQuery("")
          if (inputValue) {
            setAnchorNaturalId(null)
            fetchData()
          }
        }
        onSelect(selection)
        setInputValue("")
      } else if (!options) {
        const selection = reject(findItem, selected)
        setSelected(selection)
        onSelect(selection)
      }
    } else {
      if (options) {
        setItemsLoaded(options)
      }

      setOpen(false)
      setFocus(false)
      setSelected(rowData)
      setInputValue("")
      onSelect(rowData)
      this._isMounted && setQuery("")
    }
  }

  onOpenChange = open => {
    const {
      props: { options },
      state: { itemsLoaded },
      fetchData,
    } = this

    if (open && !options && !itemsLoaded?.length) {
      fetchData()
    }

    if (!open && !options) {
      this.setItemsLoaded([])
      this.setAnchorNaturalId(null)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      props: { value, searchableKey, isMulti, options, valueSelectorKey },
      state: { createdOptions, open },
      setSelected,
      setInputValue,
      setItemsLoaded,
      setCreatedOptions,
    } = this

    if (!this._isMounted) {
      return
    }

    if (prevState.open !== open) {
      this.onOpenChange(open)
    }

    if (!fastDeepEqual(value, prevProps.value)) {
      const inputValue = typeof searchableKey === "function" ? searchableKey(value) : value?.[searchableKey]

      setSelected(value)
      !isMulti && setInputValue(inputValue || "")
    }

    const { options: prevOptions } = prevProps
    if (isNotNil(options) && !fastDeepEqual(options, prevOptions)) {
      const updatedCreatedOptions = reject(
        created => find(propEq(valueSelectorKey, created[valueSelectorKey]), options),
        createdOptions,
      )

      setItemsLoaded(options)
      setCreatedOptions(updatedCreatedOptions)
    }
  }

  handleCreateNew = () => {
    const {
      state: { inputValue, selected, createdOptions, focusedIndexRowDropdown },
      props: {
        searchableKey,
        valueSelectorKey,
        onCreateNewOption,
        onSelect,
        shouldCreate,
        reloadRequestSearchWhenAddingNewItems,
      },
      setCreatedOptions,
      setInputValue,
      setSelected,
      getItemsToRender,
      debouncedOnSearchChange,
    } = this

    if (!shouldCreate(inputValue, focusedIndexRowDropdown)) return

    if (inputValue.length) {
      const itemsToRender = getItemsToRender()

      const isSelected = hasSome(selected, valueSelectorKey, inputValue)
      const isIncludedInOptions = hasSome(itemsToRender, valueSelectorKey, inputValue)

      const isNew = !isSelected && !isIncludedInOptions

      if (isNew) {
        const newOption = {
          isNew: true,
          [searchableKey]: inputValue,
          [valueSelectorKey]: inputValue,
          ...onCreateNewOption(inputValue),
        }
        const newSelected = [...selected, newOption]

        setCreatedOptions([...createdOptions, newOption])
        setInputValue("")
        setSelected(newSelected)
        onSelect(newSelected)
        reloadRequestSearchWhenAddingNewItems && debouncedOnSearchChange()
      } else if (isIncludedInOptions) {
        const selector = searchableKey ?? "label"
        const optionToAdd = itemsToRender.find(
          option =>
            convertToStringIfNumber(is(Function, selector) ? selector(option) : option[selector]) === inputValue,
        )
        if (optionToAdd) {
          const newSelected = [...selected, optionToAdd]

          setSelected(newSelected)
          onSelect(newSelected)
          setInputValue("")
        }
      }
    }
  }

  handleDeleteLastSelection = () => {
    const {
      state: { selected },
      props: { onSelect },
      setSelected,
    } = this
    const selection = dropLast(1, selected)
    setSelected(selection)
    onSelect(selection)
  }

  getChoices = () => {
    const {
      state: { shouldDisableSorting },
      props: { sortByName, rowHeight },
      getItemsToRender,
    } = this

    const items = getItemsToRender()
    const shouldSortListByName = sortByName && !shouldDisableSorting

    const itemsToRender = shouldSortListByName ? sortListByName(items) : items

    const totalRowHeight = itemsToRender.length * rowHeight
    const choicesHeight = totalRowHeight >= 300 ? 300 : totalRowHeight

    return {
      choicesHeight,
      itemsToRender,
    }
  }

  componentDidMount() {
    const {
      props: { options, onFocus, autoFocus, openOnFocus, isCreatable, searchableKey },
      setOpen,
      setItemsLoaded,
    } = this

    this._isMounted = true

    if (isCreatable && is(Function, searchableKey)) {
      throw new Error("searchableKey must be a string for creatable dropdowns")
    }

    this.keepOptionsInView()

    if (options) {
      setItemsLoaded(options)
    }

    if (autoFocus && openOnFocus) {
      onFocus?.()
      setOpen(true)
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleClickOutside = e => {
    const { setOpen, setFocus, setInputValue, setQuery, setItemsLoaded } = this
    const { open, focus } = this.state
    const { onClose, onBlur, options } = this.props

    if (open && this.dropdownRef && this.dropdownRef.contains(e.target)) {
      return
    }

    if (focus) {
      setQuery("")
      setInputValue("")
      setItemsLoaded(options)
      onBlur?.(this)
    }
    setOpen(false)
    setFocus(false)
    onClose?.()
  }

  handleDeleteSingleSelection = () => {
    const {
      props: { onSelect, defaultValue },
      _isMounted,
      setSelected,
    } = this
    if (_isMounted) {
      setSelected(defaultValue)
      onSelect(defaultValue)
    }
  }

  rowRenderer = (row, inputValue) => this.props.rowRenderer(row, inputValue, this.props.searchableKey)

  render() {
    const {
      state: {
        open,
        focus,
        fetching,
        isOutsideScreen,
        shouldContinueLoading,
        maxWidth,
        itemsLoaded,
        selected,
        inputValue,
      },
      props: {
        id,
        dropdownClassName,
        autoFocus,
        isMulti,
        noRowsRendererToken,
        noRowsCreatableToken,
        onSelect,
        pageSize,
        rowHeight,
        searchPlaceholderToken,
        searchableKey,
        valueSelectorKey,
        width,
        disabled,
        loading,
        disablePagination,
        options,
        tagRenderer,
        isCreatable,
        openOnFocus,
        validationMessage,
        validationState,
        getOptionLabel,
        onSearchTextChange,
        minHeight: _minHeight,
        borderRadius,
        enableClear,
        defaultValue,
        classNamePrefix,
        keepDropdownInView,
        dropdownContainerId,
        dropdownContainerRef,
        valueRenderer,
        optionsWrap,
        pressedKeyCreatesNewOption,
        pressedKeyDeleteOption,
        isSearchable,
        value,
        ariaAttributes,
        ariaInputAttributes,
        searchOnly,
        errorMessage,
        useFilterStyling,
        activeScrollEventListener,
        useSelectStyling,
        preventWhiteSpacesOnlyValue,
        inputMaxLength,
      },
      setItemsLoaded,
      setOpen,
      setFocus,
      setInputValue,
      setSelected,
      debouncedOnSearchChange,
      localSearch,
      handleRowClick,
      handleCreateNew,
      handleDeleteLastSelection,
      getChoices,
      search,
      handleClickOutside,
      handleDeleteSingleSelection,
      rowRenderer,
      setFocusedIndexRowDropdown,
    } = this

    const minHeight = useSelectStyling ? "36px" : _minHeight
    const isLocalList = !!options
    const { itemsToRender, choicesHeight } = getChoices()
    const shouldShowClear = !disabled && (!!selected?.length || !!selected?.[valueSelectorKey]) && enableClear
    const menuDropdownId = id ? `${id}-dropdown-menu` : undefined

    return (
      <OutsideClickAlerter
        {...{
          handleClickOutside,
          useCapture: true,
          activeScrollEventListener,
        }}
        className="position-relative full-width"
      >
        <>
          <StyledDropDownContainer
            {...{
              width,
              minHeight,
              borderRadius,
              focus: focus || open,
              "data-active": focus || open,
              isOutsideScreen,
              maxWidth,
              disabled,
              validationState,
              onClick: () => !disabled && !useFilterStyling && setOpen(isMulti ? true : !open),
              ...(classNamePrefix && { className: `${classNamePrefix}_dropdown-container` }),
              role: "combobox",
              "aria-expanded": open,
              "aria-controls": menuDropdownId,
              ...ariaAttributes,
              useFilterStyling,
              "data-ninja-searchable-dropdown": "",
              useSelectStyling,
            }}
            ref={ref => (this.containerRef = ref)}
            data-testid="searchable-dropdown"
          >
            <StyledInputContainer {...{ useFilterStyling, minHeight }} role="search">
              {isMulti ? (
                <MultiSelectInput
                  id={id}
                  searchOnly={searchOnly}
                  minHeight={minHeight}
                  autoFocus={autoFocus}
                  focus={focus}
                  value={inputValue}
                  selected={selected}
                  setSelected={setSelected}
                  setFocus={setFocus}
                  placeholder={localized(searchPlaceholderToken)}
                  valueSelectorKey={valueSelectorKey}
                  searchableKey={searchableKey}
                  disabled={disabled}
                  onChange={({ target: { value } }) => {
                    if (preventWhiteSpacesOnlyValue && value.trim() === "") {
                      setInputValue("")
                      return
                    }
                    isLocalList ? localSearch(value) : debouncedOnSearchChange(value)
                    setInputValue(value)
                    onSearchTextChange(value)
                    !open && setOpen(true)
                  }}
                  onDelete={option => {
                    const findItem = item => item[valueSelectorKey] === option[valueSelectorKey]
                    const selection = reject(findItem, selected)
                    setSelected(selection)
                    onSelect(selection)
                  }}
                  tagRenderer={tagRenderer}
                  handleCreateNew={handleCreateNew}
                  handleDeleteLastSelection={handleDeleteLastSelection}
                  isCreatable={isCreatable}
                  openOnFocus={openOnFocus}
                  setOpen={setOpen}
                  pressedKeyCreatesNewOption={pressedKeyCreatesNewOption}
                  pressedKeyDeleteOption={pressedKeyDeleteOption}
                  useFilterStyling={useFilterStyling}
                  maxLength={inputMaxLength}
                  ariaAttributes={ariaInputAttributes}
                />
              ) : (
                <SingleSelectInput
                  id={id}
                  readonly={!isSearchable}
                  disabled={disabled}
                  focus={focus}
                  autoFocus={autoFocus}
                  selected={selected}
                  getOptionLabel={getOptionLabel}
                  searchableKey={searchableKey}
                  value={inputValue}
                  setFocus={setFocus}
                  placeholder={localized(searchPlaceholderToken)}
                  onChange={({ target: { value } }) => {
                    if (preventWhiteSpacesOnlyValue && value.trim() === "") {
                      setInputValue("")
                      return
                    }
                    isLocalList ? localSearch(value) : debouncedOnSearchChange(value)
                    setInputValue(value)
                    onSearchTextChange()
                    !open && setOpen(true)
                  }}
                  openOnFocus={openOnFocus}
                  setOpen={setOpen}
                  valueRenderer={valueRenderer}
                  handleDeleteSingleSelection={handleDeleteSingleSelection}
                  ariaAttributes={ariaInputAttributes}
                />
              )}
              {!useFilterStyling && (
                <>
                  {shouldShowClear && !searchOnly ? (
                    <StyledClearSelection useSelectStyling={useSelectStyling}>
                      <FontAwesomeIcon
                        icon={faTimes}
                        onClick={e => {
                          e.stopPropagation()
                          if (!isMulti) {
                            setSelected(defaultValue)
                            onSelect(defaultValue)
                          } else {
                            setSelected([])
                            onSelect([])
                          }
                        }}
                      />
                    </StyledClearSelection>
                  ) : null}
                  {validationState === "error" && errorMessage && !open && (
                    <StyledErrorIconContainer useSelectStyling={useSelectStyling}>
                      <Tooltip label={errorMessage} isErrorMessage>
                        <InputErrorIcon tabIndex="0" fixedWidth={false} />
                      </Tooltip>
                    </StyledErrorIconContainer>
                  )}
                  <StyledDropDownToggle
                    {...{
                      ...(isMulti &&
                        !disabled && {
                          onClick: event => {
                            event.stopPropagation()
                            setOpen(!open)
                            setFocus(!open)
                          },
                        }),
                    }}
                  >
                    {!useSelectStyling && <StyledSeparator />}
                    <StyledToggleContainer open={open || useSelectStyling} useSelectStyling={useSelectStyling}>
                      {useSelectStyling ? <SelectIcon /> : <FontAwesomeIcon icon={faChevronDown} />}
                    </StyledToggleContainer>
                  </StyledDropDownToggle>
                </>
              )}
            </StyledInputContainer>
            {open && (
              <Dropdown
                {...{
                  id: menuDropdownId,
                  className: dropdownClassName,
                  value,
                  inputValue,
                  open,
                  selected,
                  fetching,
                  loading,
                  isLocalList,
                  isCreatable,
                  rowHeight,
                  isMulti,
                  rowRenderer,
                  valueSelectorKey,
                  noRowsRendererToken,
                  itemsToRender,
                  handleRowClick,
                  choicesHeight,
                  shouldContinueLoading,
                  disablePagination,
                  pageSize,
                  itemsLoaded,
                  search,
                  setItemsLoaded,
                  dropdownContainerId,
                  dropdownContainerRef,
                  keepInView: keepDropdownInView,
                  useFilterStyling,
                  parentRef: this.containerRef,
                  ref: ref => (this.dropdownRef = ref),
                  optionsWrap,
                  useSelectStyling,
                  setFocusedIndexRowDropdown,
                  noRowsCreatableToken,
                }}
              />
            )}
          </StyledDropDownContainer>
          {!useSelectStyling && (
            <Box position="relative">
              {validationMessage && <StyledErrorMessage position="absolute">{validationMessage}</StyledErrorMessage>}
            </Box>
          )}
        </>
      </OutsideClickAlerter>
    )
  }
}

const rowRenderer = (rowData, inputValue, searchableKey = "label") => {
  const label =
    rowData.name || (is(Function, rowData[searchableKey]) ? rowData[searchableKey]() : rowData[searchableKey])

  return (
    <div className="text-ellipsis line-height-initial">
      {inputValue ? <Highlighter text={label} highlight={inputValue} /> : label}
    </div>
  )
}

SearchableDropDown.defaultProps = {
  width: "300px",
  minHeight: "38px",
  borderRadius: "4px",
  rowHeight: 30,
  value: [],
  additionalDefaultOptions: [],
  defaultValue: null,
  searchPlaceholderToken: localizationKey("Search"),
  noRowsRendererToken: localizationKey("No results match"),
  isMulti: false,
  autoFocus: false,
  keepInView: true,
  openOnFocus: false,
  sortByName: false,
  disabled: false,
  loading: false,
  enableClear: false,
  disablePagination: false,
  searchableKey: "label",
  valueSelectorKey: "value",
  isCreatable: false,
  validationMessage: "",
  validationState: null,
  keepDropdownInView: false,
  onSelect: () => {},
  dataFormatter: rowData => rowData,
  responseParser: payload => payload,
  rowRenderer,
  shouldSelectOption: () => true,
  onCreateNewOption: () => {},
  getOptionValue: option => option.value,
  getOptionLabel: (option, searchableKey) =>
    is(Function, searchableKey) ? searchableKey(option) : option?.[searchableKey],
  excludeSelectedFromSelectableValues: false,
  onSearchTextChange: () => {},
  classNamePrefix: "",
  shouldCreate: () => true,
  searchParams: ({ query }) => query && { name: query },
  getAnchorNaturalId: ({ anchorNaturalId }) => ({ anchorNaturalId }),
  lastItemKey: "naturalId",
  dataFilter: () => true,
  optionsWrap: false,
  onBlur: () => {},
  isSearchable: true,
  ariaAttributes: {},
  ariaInputAttributes: {},
  errorMessage: "",
  useFilterStyling: false,
  activeScrollEventListener: false,
  useSelectStyling: false,
  inputMaxLength: null,
  noRowsCreatableToken: null,
}

SearchableDropDown.propTypes = {
  id: PropTypes.string,
  dropdownClassName: PropTypes.string,
  additionalDefaultOptions: PropTypes.array,
  autoFocus: PropTypes.bool,
  valueSelectorKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  searchableKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
  dataFormatter: PropTypes.func,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  shouldSelectOption: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  keepInView: PropTypes.bool,
  keepDropdownInView: PropTypes.bool,
  openOnFocus: PropTypes.bool,
  sortByName: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  enableClear: PropTypes.bool,
  disablePagination: PropTypes.bool,
  tagRenderer: PropTypes.func,
  isCreatable: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onCreateNewOption: PropTypes.func,
  validationMessage: PropTypes.string,
  validationState: PropTypes.string,
  getOptionLabel: PropTypes.func,
  excludeSelectedFromSelectableValues: PropTypes.bool,
  onSearchTextChange: PropTypes.func,
  classNamePrefix: PropTypes.string,
  shouldCreate: PropTypes.func,
  dropdownContainerId: PropTypes.string,
  dropdownContainerRef: PropTypes.object,
  optionsWrap: PropTypes.bool,
  getDefaultSelected: PropTypes.func,
  onBlur: PropTypes.func,
  searchParams: PropTypes.func,
  getAnchorNaturalId: PropTypes.func,
  isSearchable: PropTypes.bool,
  ariaAttributes: PropTypes.object,
  ariaInputAttributes: PropTypes.object,
  searchOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  useFilterStyling: PropTypes.bool,
  activeScrollEventListener: PropTypes.bool,
  useSelectStyling: PropTypes.bool,
  reloadRequestSearchWhenAddingNewItems: PropTypes.bool,
  preventWhiteSpacesOnlyValue: PropTypes.bool,
  inputMaxLength: PropTypes.number,
  noRowsCreatableToken: PropTypes.string,
}

export default SearchableDropDown

import PropTypes from "prop-types"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import { css } from "@emotion/react"
import tokens from "@ninjaone/tokens"

export const shouldForwardInteractiveProp = prop =>
  isPropValid(prop) && !["increaseContrast", "textDecoration", "fontWeight"].includes(prop)

const getAnchorStyles = ({ fontWeight, increaseContrast, textDecoration, theme }) => css`
  font-size: ${tokens.typography.fontSize.body};
  font-weight: ${fontWeight};
  line-height: ${tokens.typography.lineHeight};
  text-decoration: none;
  color: ${increaseContrast ? theme.colorTextActionStrong : theme.colorTextAction};

  // TODO: Remove once we get rid of bootstrap.css (needed to override anchor hover and focus styles)
  &:hover,
  &:focus {
    color: ${increaseContrast ? theme.colorTextActionStrong : theme.colorTextAction};
  }

  &:focus-visible {
    outline-color: ${theme.colorForegroundFocus};
  }

  &:hover {
    text-decoration: ${textDecoration ? "underline" : "none"};
  }
`

const StyledAnchor = styled("a", { shouldForwardProp: shouldForwardInteractiveProp })`
  ${props => getAnchorStyles(props)}
`

const StyledSpan = styled("span", { shouldForwardProp: shouldForwardInteractiveProp })`
  a {
    ${props => getAnchorStyles(props)}
  }
`

const Link = ({
  href,
  children,
  className,
  onClick,
  onMouseOver,
  onMouseOut,
  target,
  rel,
  download,
  childIsLink,
  fontWeight = tokens.typography.fontWeight.regular,
  increaseContrast,
  textDecoration = true,
}) => {
  if (childIsLink) {
    return (
      <StyledSpan {...{ className, onClick, onMouseOver, onMouseOut, fontWeight, increaseContrast, textDecoration }}>
        {children}
      </StyledSpan>
    )
  }

  return (
    <StyledAnchor
      {...{
        href,
        className,
        onClick,
        onMouseOver,
        onMouseOut,
        target,
        rel,
        download,
        fontWeight,
        increaseContrast,
        textDecoration,
      }}
    >
      {children}
    </StyledAnchor>
  )
}

Link.propTypes = {
  /**
   * The URL for the link.
   */
  href: PropTypes.string.isRequired,
  /**
   * Text content for the link.
   */
  children: PropTypes.node.isRequired,
  /**
   * CSS class name for the link.
   */
  className: PropTypes.string,
  /**
   * Increases the color contrast of the component's text to meet minimum contrast ratio.
   */
  increaseContrast: PropTypes.bool,
  /**
   * A function to be called when the link is clicked.
   */
  onClick: PropTypes.func,
  /**
   * A function to be called when the mouse pointer is moved onto the link.
   */
  onMouseOver: PropTypes.func,
  /**
   * A function to be called when the mouse pointer moves out of link.
   */
  onMouseOut: PropTypes.func,
  /**
   * The relationship of the linked URL as space-separated link types.
   */
  target: PropTypes.string,
  /**
   * The name of the browsing context used to display the linked URL.
   */
  rel: PropTypes.string,
  /**
   * Causes the browser to treat the linked URL as a download. Can be used with or without a filename value.
   */
  download: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  /**
   * Indicates whether the child element itself is a link.
   */
  childIsLink: PropTypes.bool,
  /**
   * The font weight for the link text.
   */
  fontWeight: PropTypes.oneOf([400, 500]),
  /**
   * Determines if the decoration for the text should be used on hover.
   */
  textDecoration: PropTypes.bool,
}

export default Link

import styled from "@emotion/styled"
import tokens from "@ninjaone/tokens"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { Body } from "@ninjaone/components"
import { StyledTooltipContent, StyledTooltipContentHeader } from "./SimpleChartTooltip"

const StyledStatusBar = styled.div`
  width: 5px;
  height: 23px;
`

const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacing[2]};

  &:not(:first-of-type) {
    padding-top: ${tokens.spacing[1]};
  }

  &:not(:last-child) {
    padding-bottom: ${tokens.spacing[1]};
    border-bottom: 1px solid ${({ theme }) => theme.colorBorderWeak};
  }
`

const ComplexChartTooltip = ({ title, subTitle, rows }) => {
  return (
    <StyledTooltipContent width="170px">
      <output aria-live="assertive">
        <StyledTooltipContentHeader>
          <Body type="bodyXs">{title}</Body>
          <Body color="colorTextWeakest" type="bodyXs">
            {subTitle}
          </Body>
        </StyledTooltipContentHeader>
        <Box paddingLeft={tokens.spacing[2]}>
          {rows.map(({ name, value, valueSecondary, statusBarColor }, index) => {
            return (
              <StyledRow key={name}>
                <Flex alignItems="center" gap={tokens.spacing[2]}>
                  {statusBarColor && <StyledStatusBar style={{ backgroundColor: statusBarColor }} />}
                  {!!name && (
                    <Body color="colorTextWeak" type="bodyXs">
                      {name}
                    </Body>
                  )}
                </Flex>
                <Flex gap="6px" alignItems="center" marginLeft="auto" marginRight={tokens.spacing[2]}>
                  {!!value && (
                    <Body color="colorTextWeak" type="bodyXs">
                      {value}
                    </Body>
                  )}
                  {!!valueSecondary && (
                    <>
                      <Body color="colorTextWeakest" type="bodyXs">
                        {valueSecondary}
                      </Body>
                    </>
                  )}
                </Flex>
              </StyledRow>
            )
          })}
        </Box>
      </output>
    </StyledTooltipContent>
  )
}

export default ComplexChartTooltip

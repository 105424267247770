import { useEffect, useRef } from "react"
import { useMountedState } from "js/includes/common/hooks"

const useFilterCollision = (activeFilter, filter, selectedValues) => {
  const [shouldAlignRight, setShouldAlignRight] = useMountedState(false)
  const filterContainerRef = useRef()
  const filterButtonRef = useRef()

  useEffect(() => {
    if (activeFilter === filter && filterButtonRef.current && filterContainerRef.current) {
      // If the left positioning of the filter button plus the width of the filter container
      // is larger than the browser viewport then align the filter container to the right
      const innerWidth = window.innerWidth
      const buttonBoundingRect = filterButtonRef.current.getBoundingClientRect()
      const containerBoundingRect = filterContainerRef.current.getBoundingClientRect()
      if (buttonBoundingRect.left + containerBoundingRect.width >= innerWidth) {
        setShouldAlignRight(true)
      } else {
        setShouldAlignRight(false)
      }
    }
  }, [activeFilter, filter, setShouldAlignRight, selectedValues])

  return {
    shouldAlignRight,
    filterContainerRef,
    filterButtonRef,
  }
}

export default useFilterCollision

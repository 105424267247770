import styled from "@emotion/styled"
import PropTypes from "prop-types"
import { spacing } from "@ninjaone/tokens"

import { isRequiredIf } from "@ninjaone/utils"
import { Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import { localized } from "@ninjaone/webapp/src/js/includes/common/utils/ssrAndWebUtils/localization"

import Body from "../Typography/Body"
import HoverTooltip from "../HoverTooltip"

const StyledFlex = styled(Flex)`
  & > button {
    height: 21px;
    width: 14px;
  }
`
// TODO: Remove this styling when bootstrap is removed.
const StyledLabel = styled.label`
  font-weight: ${({ fontWeight }) => fontWeight ?? 400};
  margin: 0;
  vertical-align: top;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Label = ({
  disabled,
  fontWeight,
  textWrapLineLimit,
  textWrap = false,
  id,
  labelFor,
  labelText,
  forInputElement = true,
  noMargin,
  required,
  tooltipText,
}) => (
  <StyledFlex alignItems="center" gap={spacing[1]} marginBottom={noMargin ? 0 : spacing[1]}>
    <Body as="span" {...{ fontWeight, textWrapLineLimit, textWrap }}>
      <StyledLabel {...(forInputElement ? { htmlFor: labelFor } : { id })} {...{ fontWeight }}>
        {labelText}
      </StyledLabel>
      {!disabled && required && <span aria-hidden="true">*</span>}
    </Body>

    {tooltipText && (
      <HoverTooltip
        text={tooltipText}
        triggerColor="colorTextWeakest"
        triggerSize="sm"
        triggerAriaLabel={localized("More information")}
      />
    )}
  </StyledFlex>
)

Label.propTypes = {
  disabled: PropTypes.bool,
  forInputElement: PropTypes.bool,
  id: isRequiredIf(
    PropTypes.string,
    props => props.forInputElement === "false",
    "'id' is required for a radio group label",
  ),
  labelFor: isRequiredIf(
    PropTypes.string,
    props => props.forInputElement === "true",
    "'labelFor' is required for input elements'",
  ),
  labelText: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
  required: PropTypes.bool,
  tooltipText: PropTypes.string,
  type: PropTypes.oneOf(["input", "radio"]),
}

import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { TableFilterButton } from "@ninjaone/components"
import { sizer } from "@ninjaone/utils"
import tokens from "@ninjaone/tokens"
import { useMountedState } from "@ninjaone/webapp/src/js/includes/common/hooks"
import { Box, Flex } from "@ninjaone/webapp/src/js/includes/components/Styled"
import OutsideClickAlerter from "@ninjaone/webapp/src/js/includes/components/OutsideClickAlerter"
import useFilterCollision from "@ninjaone/webapp/src/js/includes/deviceSearch/components/filters/useFilterCollision"

const StyledContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colorBackground};
  min-height: ${({ minHeight }) => minHeight};
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colorBorderWeak};
  border-radius: ${tokens.borderRadius[1]};
  outline: 0px !important;
  padding: ${tokens.spacing[4]};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
`

const WithContainer = ({ useContainer, containerProps, children }) =>
  useContainer ? (
    <StyledContainer
      {...{
        flexDirection: "column",
        alignItems: "start",
        ...containerProps,
      }}
    >
      {children}
    </StyledContainer>
  ) : (
    children
  )

const TableFilter = ({
  labelToken,
  filters,
  isRemovable,
  onRemove,
  alwaysShowRemove,
  hideLabelOnSingleSelect,
  useContainer = true,
  children,
  containerProps = {},
  openOnMount,
  onDropdownClose,
  labelSelectorKey,
  onDropdownOpen,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useMountedState(!!openOnMount)
  const activeFilter = isOpen ? labelToken : null
  const { shouldAlignRight, filterContainerRef, filterButtonRef } = useFilterCollision(
    activeFilter,
    labelToken,
    filters,
  )

  return (
    <Box width="fit-content">
      <OutsideClickAlerter
        handleClickOutside={() => {
          isOpen && onDropdownClose?.()
          setIsOpen(false)
        }}
        useCapture={true}
      >
        <Box position="relative">
          <TableFilterButton
            {...{
              labelToken,
              filters,
              hideTooltip: isOpen,
              handleClick: () => {
                setIsOpen(isOpen => !isOpen)
                !isOpen && onDropdownOpen?.()
              },
              isRemovable,
              onRemove: () => {
                onRemove?.()
                isOpen && setIsOpen(false)
              },
              alwaysShowRemove,
              hideLabelOnSingleSelect,
              labelSelectorKey,
              disabled,
              filterButtonRef,
            }}
          />
          <Box
            ref={filterContainerRef}
            position="absolute"
            top={`calc(100% + ${sizer(1)})`}
            zIndex={1000}
            {...(shouldAlignRight ? { right: "1px" } : { left: "1px" })}
          >
            {isOpen && (
              <WithContainer
                {...{
                  useContainer,
                  containerProps,
                }}
              >
                {typeof children === "function" ? children({ isOpen, setIsOpen }) : children}
              </WithContainer>
            )}
          </Box>
        </Box>
      </OutsideClickAlerter>
    </Box>
  )
}

TableFilter.propTypes = {
  labelToken: PropTypes.string.isRequired,
  filters: PropTypes.array,
  isRemovable: PropTypes.bool,
  onRemove: PropTypes.func,
  alwaysShowRemove: PropTypes.bool,
  hideLabelOnSingleSelect: PropTypes.bool,
  useContainer: PropTypes.bool,
  containerProps: PropTypes.object,
  openOnMount: PropTypes.bool,
  /**
   * The children can be either a node component or a function that receives Open state
   */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
}

export default TableFilter

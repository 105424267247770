import { TableFilterButton } from "@ninjaone/components"
import { useEffect } from "react"

const SingleValueFilter = ({
  filter: {
    name,
    labelToken,
    componentProps: { value },
  },
  updateFilter,
  alwaysShowRemove,
  onRemove,
  disabled,
}) => {
  useEffect(() => {
    updateFilter(name, value)
  }, [name, updateFilter, value])

  return (
    <TableFilterButton
      {...{
        labelToken,
        alwaysShowRemove,
        onRemove: () => {
          onRemove?.(name)
          updateFilter(name)
        },
        disabled,
      }}
    />
  )
}

export default SingleValueFilter

import { useCallback } from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

import { CloseIcon } from "@ninjaone/icons"
import { hexToHexA, sizer } from "@ninjaone/utils"
import { Box } from "@ninjaone/webapp/src/js/includes/components/Styled"

import { localized } from "@ninjaone/webapp/src/js/includes/common/utils"
import tokens from "@ninjaone/tokens"

import { VARIANTS } from "./Button"
import ButtonGroup from "./ButtonGroup"
import TitleGroup from "./TitleGroup"
import Heading from "./Typography/Heading"
import Body from "./Typography/Body"
import AlertMessage from "./AlertMessage"
import withFocusTrap from "./hoc/withFocusTrap"

function getModalWidth(size) {
  switch (size) {
    case "lg":
      return "980px"
    case "md":
      return "720px"
    case "sm":
    default:
      return "540px"
  }
}

const StyledModalDialogBackground = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${({ theme }) => hexToHexA(theme.colorBackgroundAccentNeutralDarkest, 0.5)};
  overflow-y: auto;
  z-index: 2050;
  min-width: 1024px;
  height: 100vh;
  display: flex;
`

const StyledButtonGroup = styled(ButtonGroup)`
  width: 100%;
`
const StyledModalDialog = styled.div`
  width: ${({ size }) => getModalWidth(size)};
  margin: auto;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorBackground};
  box-shadow: ${({ theme }) => theme.elevationStrongest};
  border-radius: ${sizer(1)};
  border: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  position: relative;
  ${({ scrollable, scrollableMaxHeight, preventScrollableMaxHeight, scrollableMinHeight }) =>
    scrollable &&
    `
    min-height:  ${scrollableMinHeight ? `${scrollableMinHeight}px` : "480px"};
    ${
      preventScrollableMaxHeight
        ? `max-height: calc(100% - ${tokens.spacing[6]})`
        : `max-height: ${
            scrollableMaxHeight ? `min(${scrollableMaxHeight}px, calc(100% - 200px));` : "calc(100% - 200px);"
          }`
    }
  `};
`

const StyledXButton = styled.button`
  position: absolute;
  top: ${sizer(4)};
  right: ${sizer(4)};
  border: none;
  background: none;
  outline: none;
  width: 38px;
  height: 38px;
  padding: 0;
  cursor: pointer;
  border-radius: ${sizer(1)};
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colorForegroundHover};
  }

  &:focus-visible {
    box-shadow: ${({ theme }) =>
      `0 0 0 2px ${theme.color.primary["075"]}`}; // TODO: replace with elevation token when available
  }
`

const StyledChildrenContainer = styled(Box)`
  padding: ${sizer(6, 6, 1, 6)};
  overflow-y: ${({ scrollable }) => (scrollable ? "auto" : "initial")};
  width: 100%;
  min-width: 0;
  ${({ scrollable }) => scrollable && `flex-grow: 1;`}
`

const StyledHr = styled.hr`
  border-top: 1px solid ${({ theme }) => theme.colorBorderWeakest};
  width: 100%;
  margin: 0;
`

function Modal({
  backgroundClassName,
  children,
  buttons = [],
  unmount = () => {},
  headingComponent,
  showTitleSection = false,
  closeAction,
  description,
  heading,
  titleGroup,
  size = "sm",
  cancelable,
  buttonRenderer,
  withCloseX = true,
  showCloseButton = true,
  scrollable = false,
  scrollableMaxHeight,
  preventScrollableMaxHeight = false,
  scrollableMinHeight,
  id,
  showFooter = true,
  focusTrapDisabled,
  footerAlertMessage,
}) {
  const handleCloseClick = useCallback(() => {
    if (closeAction) {
      closeAction({ unmount })
    } else {
      unmount()
    }
  }, [closeAction, unmount])

  return (
    <StyledModalDialogBackground {...{ id, className: backgroundClassName }}>
      <StyledModalDialog
        {...{ size, scrollable, scrollableMaxHeight, preventScrollableMaxHeight, scrollableMinHeight }}
        data-testid="modal"
        id="dialog"
        {...(heading && { role: "dialog", "aria-modal": "true", "aria-labelledby": "dialogLabel" })}
        {...(!focusTrapDisabled && { tabIndex: -1 })}
      >
        {withCloseX && (
          <StyledXButton onClick={handleCloseClick} type="button">
            <CloseIcon label={localized("general.close")} data-testid="close-modal-icon" color="colorTextStrong" />
          </StyledXButton>
        )}
        {heading && (
          <Box padding={sizer(6, 6, 0, 6)} marginRight={sizer(8)}>
            <Heading color="colorTextStrong" level={2} type="headingL" id="dialogLabel">
              {heading}
            </Heading>
          </Box>
        )}
        {description && (
          <Box padding={sizer(1, 6, 0, 6)} marginRight={sizer(8)}>
            <Body textWrap wordWrap="break-word" color="colorTextWeak">
              {description}
            </Body>
          </Box>
        )}
        {titleGroup && (
          <Box padding={sizer(6, 6, 0, 6)} marginRight={sizer(8)}>
            <TitleGroup
              textWrap
              type="headingL"
              wordWrap="break-word"
              textWrapLineLimit={2}
              color="colorTextStrong"
              descriptionColor="colorTextWeak"
              {...titleGroup}
            />
          </Box>
        )}
        {headingComponent && (
          <Box padding={sizer(0, 6, 0, 6)} marginRight={sizer(8)}>
            {headingComponent}
          </Box>
        )}

        {showTitleSection && (
          <Box padding={sizer(0, 0, 0, 6)} marginRight={sizer(8)}>
            <StyledHr />
          </Box>
        )}
        <StyledChildrenContainer data-testid="confirmation-desc" {...{ scrollable }}>
          {children}
        </StyledChildrenContainer>
        {showFooter && (
          <Box padding={sizer(8, 6, 6, 6)}>
            {footerAlertMessage && (
              <Box marginBottom={tokens.spacing[4]}>
                <AlertMessage variant="danger" {...footerAlertMessage} />
              </Box>
            )}
            {buttonRenderer?.() ?? (
              <StyledButtonGroup
                buttons={[
                  ...(showCloseButton
                    ? [
                        {
                          type: cancelable ? "cancel" : "close",
                          onClick: handleCloseClick,
                          variant: VARIANTS.SECONDARY,
                        },
                      ]
                    : []),
                  ...buttons,
                ]}
              />
            )}
          </Box>
        )}
      </StyledModalDialog>
    </StyledModalDialogBackground>
  )
}

export default withFocusTrap(Modal)

Modal.propTypes = {
  children: PropTypes.node,
  buttons: PropTypes.array,
  description: PropTypes.string,
  heading: PropTypes.string,
  unmount: PropTypes.func,
  titleGroup: PropTypes.shape({
    titleText: PropTypes.string,
    titleToken: PropTypes.string,
    TitleTailComponent: PropTypes.func,
    TitleComponent: PropTypes.func,
    descriptionText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    ]),
    descriptionToken: PropTypes.string,
    DescriptionComponent: PropTypes.func,
    SubheaderComponent: PropTypes.func,
    textWrapLineLimit: PropTypes.number,
    size: PropTypes.string,
    textWrap: PropTypes.bool,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  cancelable: PropTypes.bool,
  buttonRenderer: PropTypes.func,
  id: PropTypes.string,
  withCloseX: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  closeAction: PropTypes.func,
  scrollable: PropTypes.bool,
  scrollableMaxHeight: PropTypes.number,
  scrollableMinHeight: PropTypes.number,
  preventScrollableMaxHeight: PropTypes.bool,
  focusTrapDisabled: PropTypes.bool,
  /** Displays an always-visible alert message at the bottom of the modal's content, right above the bottom action buttons. */
  footerAlertMessage: PropTypes.shape({
    variant: PropTypes.oneOf(["danger", "warning", "success", "info"]),
    labelToken: PropTypes.string,
    titleToken: PropTypes.string,
    link: PropTypes.string,
    children: PropTypes.node,
    iconAlign: PropTypes.string,
  }),
}

import { useRef } from "react"
import PropTypes from "prop-types"
import isPropValid from "@emotion/is-prop-valid"
import styled from "@emotion/styled"
import { Root, Trigger, Portal, Content, Provider } from "@radix-ui/react-tooltip"
import tokens from "@ninjaone/tokens"
import * as ninjaIcons from "@ninjaone/icons"
import { useMountedState } from "@ninjaone/utils"
import { resetButtonStyle } from "./Styled"
import Body from "./Typography/Body"

const getTriggerStylesFromSize = size => {
  if (size === "xs") {
    return `
      width: 20px;
      height: 20px;
    `
  } else if (size === "sm") {
    return `
      width: 24px;
      height: 24px;
    `
  } else if (size === "md") {
    return `
      width: 28px;
      height: 28px;
    `
  }
}

const shouldIgnoreProps = prop => !["triggerSize", "contentPadding"].includes(prop)
const baseContentZIndex = 3000

const StyledBaseContent = styled(Content, {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  outline: none;
  padding-top: 1px; // avoids trigger outline from getting cutoff
  z-index: ${baseContentZIndex};
`

const StyledContent = styled("div", {
  shouldForwardProp: prop => isPropValid(prop) || shouldIgnoreProps(prop),
})`
  width: 400px;
  padding: ${tokens.spacing[4]};
  border-radius: ${tokens.borderRadius[1]};
  border: ${({ theme }) => `1px solid ${theme.colorBorderWeak}`};
  background: ${({ theme }) => theme.colorBackgroundWidget};
  padding: ${({ contentPadding }) => contentPadding};
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15); // replace box shadow with token
`

const StyledTrigger = styled(Trigger)`
  position: relative;
  cursor: pointer;
  border-radius: ${tokens.borderRadius[1]};

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colorForegroundFocus};
  }
`

const StyledIconButton = styled.button`
  ${resetButtonStyle}
  ${({ triggerSize }) => getTriggerStylesFromSize(triggerSize)}
  display: flex;
  justify-content: center;
  align-items: center;
`

const HoverTooltip = ({
  text,
  align = "start",
  alignOffset = 0,
  onOpenChange,
  side = "bottom",
  sideOffset = 0,
  triggerAriaLabel,
  hideDelay = 750,
  triggerComponent,
  triggerColor,
  triggerIconName = "RegularInfoCircleIconLight",
  triggerSize = "md",
  contentPadding = tokens.spacing[3],
  textComponent,
}) => {
  const [isOpen, setIsOpen] = useMountedState(false)
  const hoveredOutsideWithMouse = useRef(false)
  const timeoutRef = useRef(null)
  const TriggerIcon = ninjaIcons[triggerIconName]

  const handleOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    setIsOpen(true)
  }

  const closeViaTimeout = () => {
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false)
      hoveredOutsideWithMouse.current = true
    }, hideDelay)
  }

  return (
    <Provider>
      <Root open={isOpen}>
        <StyledTrigger
          {...{
            onFocus: handleOpen,
            onBlur: () => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current)
              }
              setIsOpen(false)
            },
            onMouseEnter: handleOpen,
            onMouseLeave: event => {
              closeViaTimeout()
            },
            onClick: () => {
              if (isOpen) {
                if (timeoutRef.current) {
                  clearTimeout(timeoutRef.current)
                }
                setIsOpen(false)
              }
            },
            "data-ninja-hover-popover-trigger": "",
            triggerSize,
            asChild: true,
          }}
        >
          {triggerComponent?.() ?? (
            <StyledIconButton aria-label={triggerAriaLabel} type="button">
              <TriggerIcon size={triggerSize} color={triggerColor} />
            </StyledIconButton>
          )}
        </StyledTrigger>
        <Portal>
          <StyledBaseContent
            {...{
              align,
              alignOffset,
              avoidCollisions: true,
              side,
              sideOffset,
              triggerSize,
              onMouseEnter: handleOpen,
              onMouseLeave: event => {
                closeViaTimeout()
              },
              onEscapeKeyDown: () => {
                setIsOpen(false)
              },
            }}
          >
            <StyledContent {...{ contentPadding }}>{textComponent?.() ?? <Body textWrap>{text}</Body>}</StyledContent>
          </StyledBaseContent>
        </Portal>
      </Root>
    </Provider>
  )
}

HoverTooltip.propTypes = {
  /**
   * The text for the tooltip.
   */
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * The horizontal alignment of the tooltip against the trigger.
   * May change when collisions occur.
   */
  align: PropTypes.oneOf(["start", "center", "end"]),
  /**
   * An offset in pixels from the "start" or "end" alignment options.
   */
  alignOffset: PropTypes.number,
  /**
   * Event handler called when the open state of the tooltip changes.
   * This event handler is called before the component rerenders.
   */
  onOpenChange: PropTypes.func,
  /**
   * The preferred side of the trigger to render against when open.
   * Will be reversed when collisions occur and `avoidCollisions` is enabled.
   */
  side: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  /**
   * The distance in pixels from the trigger.
   */
  sideOffset: PropTypes.number,
  /**
   * Aria label for the tooltip trigger. This should be used when the content
   * of the trigger does not provide suitable context for the action being
   * performed.
   */
  triggerAriaLabel: PropTypes.string,
  /**
   * Delay until tooltip is hidden after hovering off with mouse.
   */
  hideDelay: PropTypes.number,
  /**
   * Icon name from "@ninjaone/icons" used for size trigger.
   */
  triggerIconName: PropTypes.string,
  /**
   * Icon size used for the trigger.
   */
  triggerSize: PropTypes.oneOf(["xs", "sm", "md"]),
  /**
   * Padding used around the content.
   */
  contentPadding: PropTypes.string,
  /**
   * Optional to pass a component as to render the text
   */
  textComponent: PropTypes.func,
  /**
   * Optional to pass a component as to render the trigger for the tooltip
   */
  triggerComponent: PropTypes.func,
}

export default HoverTooltip
